import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  getRulesRequest: ['rules'],
  getRulesSuccess: null,
  getEditRequest: ['rule'],
  getEditSuccess: null,
});

const INITIAL_STATE = Immutable({
  actions: [],
  conditions: [],
  typeRules: [],
  rule: {},
  reasons: [],
  category: [],
});

const getRules = (state = INITIAL_STATE, action) => state.merge({ ...action });
const getRule = (state = INITIAL_STATE, action) => state.merge({ ...action });

export default createReducer(INITIAL_STATE, {
  [Types.GET_RULES_SUCCESS]: getRules,
  [Types.GET_EDIT_SUCCESS]: getRule,
});
