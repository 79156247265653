export function getKPIs(data) {
  const requests_initiated =
    data?.get_company_return_indicators.requests_initiated;
  const requests_approved = data?.get_company_return_indicators.requests_approved;
  const requests_completed =
    data?.get_company_return_indicators.requests_completed;

  return {
    requests_initiated,
    requests_approved,
    requests_completed,
  };
}
