import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { BaseUrl } from '../../../helpers/settings';
import { Types } from './index';
import { clearToken, getToken, setToken } from '../../../helpers/utility';

const request = axios.create({
  baseURL: `${BaseUrl}/auth/refresh`,
  headers: { Authorization: `Bearer ${getToken()}` },
});

export function* loginRequest() {
  yield takeEvery(Types.LOGIN_REQUEST, function*({ token }) {
    try {
      const jwt = jwtDecode(token);
      yield put({
        type: Types.LOGIN_SUCCESS,
        token,
        profile: jwt.type,
        infoUser: jwt.info,
        isLogin: true,
      });
    } catch (e) {}
  });
}

export function* loginSuccess() {
  yield takeEvery(Types.LOGIN_SUCCESS, function*({ token, profile }) {
    yield setToken(token);
    if (profile === 'admin') {
      yield put(push('/administration'));
    }
    if (profile === 'user') {
      yield put(push('/dashboard/return'));
    }
  });
}

export function* checkAuthorization() {
  yield takeEvery(Types.CHECK_AUTHORIZATION, function*() {
    try {
      const token = getToken();
      if (token) {
        const jwt = jwtDecode(token);
        if (jwt.exp * 1000 > new Date().getTime()) {
          yield put({
            type: Types.LOGIN_SUCCESS,
            token,
            profile: jwt.type,
            infoUser: jwt.info,
            isLogin: true,
          });
        } else {
          const refresh = yield request.post();
          yield put({
            type: Types.LOGIN_SUCCESS,
            token: refresh.data.access_token,
            profile: jwt.type,
            infoUser: jwt.info,
            isLogin: true,
          });
        }
      }
    } catch (e) {
      clearToken();
    }
  });
}

export function* logout() {
  yield takeEvery(Types.LOGOUT, function() {
    clearToken();
  });
}
export function* checking() {
  yield takeEvery(Types.CHECKING, function*({ isBlock, message }) {
    yield put({
      type: Types.CHECKING_SUCCESS,
      isBlock,
      message,
    });
  });
}
export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(loginSuccess),
    fork(logout),
    fork(checking),
    fork(checkAuthorization),
  ]);
}
