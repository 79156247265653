import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { Types } from './index';

export function* getOrders() {
  yield takeEvery(Types.GET_ORDERS_REQUEST, function*({
    orders,
    order_by,
    order_direction,
    page,
  }) {
    yield put({
      type: Types.GET_ORDERS_SUCCESS,
      orders: orders?.data,
      total: orders?.total,
      current_page: orders?.current_page,
      order_by,
      order_direction,
      page,
    });
  });
}

export function* getCourier() {
  yield takeEvery(Types.GET_ORDER_COURIER_REQUEST, function*({ courier }) {
    yield put({
      type: Types.GET_ORDER_COURIER_SUCCESS,
      courier,
    });
  });
}

export function* getFeedback() {
  yield takeEvery(Types.GET_FEEDBACK_REQUEST, function*({
    feedback,
    feedbackOrder_by,
    feedbackOrder_direction,
    page,
  }) {
    const list = yield feedback?.data.map(fbk => {
      return {
        id: fbk.id,
        order_reference: fbk.shipment.order_reference,
        customer_name: fbk.shipment.customer_name,
        customer_email: fbk.shipment.customer_email,
        rating: fbk.rating,
        created_at: fbk.created_at,
      };
    });
    yield put({
      type: Types.GET_FEEDBACK_SUCCESS,
      feedback: list,
      totalFeedback: feedback?.total,
      current_page_feedback: feedback?.current_page,
      feedbackOrder_by,
      feedbackOrder_direction,
      page,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getOrders), fork(getCourier), fork(getFeedback)]);
}
