import gql from 'graphql-tag';

export const GET_STORE_APPLICATION = gql`
  query getStoreApps {
    get_my_stores {
      id
      name
      logo_src
    }
  }
`;
