import StoreLoginPage from '../Pages/StoreLoginPage/redux';
import StoreReturnEmail from '../Pages/ReturnModule/EmailNotificationsPage/redux';
import StoreReturnRules from '../Pages/ReturnModule/ReturnRulesPage/redux';
import StoreReturnStatus from '../Pages/ReturnModule/ReturnStatusesPage/redux';
import StorePeriodFilter from '../components/PeriodFilterComponent/redux';
import StoreDashboardHome from '../Pages/ReturnModule/HomePage/redux';
import StoreDashRouteHome from '../Pages/RouteModule/HomePage/redux';
import StoreRouteOrders from '../Pages/RouteModule/ShipmentsOverviewPage/redux';
import StoreIntegrations from '../components/Integrations/redux';

export default {
  StoreLoginPage,
  StoreReturnEmail,
  StoreReturnRules,
  StoreReturnStatus,
  StorePeriodFilter,
  StoreDashboardHome,
  StoreDashRouteHome,
  StoreRouteOrders,
  StoreIntegrations,
};
