import styled from 'styled-components';
import { Layout } from 'antd';

export const HeaderWrapper = styled(Layout.Header)`
  max-width: ${props => props.maxwidth || '100%'};
  padding: 16px 16px 0;
  background: ${props => props.theme.black};
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${props => props.theme.lg}) {
    padding: 32px 32px 0;
  }

  .btn-header {
    display: none;
    @media (min-width: ${props => props.theme.lg}) {
      display: block;
      font-size: 14px;
      line-height: 20px;
      height: 42px;
      padding: 10px 40px;
      letter-spacing: 0.1em;
    }
  }
`;
