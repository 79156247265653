import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import LoadingWrapper from './style';

const Loading = ({ loading }) => {
  if (loading) {
    return (
      <LoadingWrapper>
        <div className="container">
          <BeatLoader size={20} color={'#17D892'} loading={loading} />
        </div>
      </LoadingWrapper>
    );
  }
  return null;
};

export default Loading;
