import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  FormRequest: ['integration'],
  FormSuccess: null,
  FormReset: null,
  FormResetSuccess: null,
});

const INITIAL_STATE = Immutable({
  integration: {
    allowed_operations: '[]',
    description: '',
    expiration: new Date(),
  },
});

const form = (state = INITIAL_STATE, action) => state.merge({ ...action });

const reset = (state = INITIAL_STATE) => state.merge(INITIAL_STATE);

export default createReducer(INITIAL_STATE, {
  [Types.FORM_SUCCESS]: form,
  [Types.FORM_RESET_SUCCESS]: reset,
});
