import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rootSaga from './sagas';
import reducers from './reducers';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = createStore(
  combineReducers({ ...reducers, router: connectRouter(history) }),
  compose(applyMiddleware(...middlewares, routerMiddleware(history)))
);

sagaMiddleware.run(rootSaga);

export { store, history };
