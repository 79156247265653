import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  getOrdersRequest: ['orders'],
  getOrdersSuccess: null,
  getOrderCourierRequest: ['courier'],
  getOrderCourierSuccess: null,
  getFeedbackRequest: ['feedback'],
  getFeedbackSuccess: null,
});

const INITIAL_STATE = Immutable({
  orders: [],
  feedback: [],
  total: 0,
  totalFeedback: 0,
  feedbackOrder_by: '',
  feedbackOrder_direction: '',
  order_by: '',
  order_direction: '',
  page: null,
  current_page: 1,
  current_page_feedback: 1,
  status: undefined,
  courier: undefined,
});

const getOrders = (state = INITIAL_STATE, action) => state.merge({ ...action });

const getCourier = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

const getFeedback = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

export default createReducer(INITIAL_STATE, {
  [Types.GET_ORDERS_SUCCESS]: getOrders,
  [Types.GET_ORDER_COURIER_SUCCESS]: getCourier,
  [Types.GET_FEEDBACK_SUCCESS]: getFeedback,
});
