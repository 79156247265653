import React from 'react';
import 'fix-date';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache as Cache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import StartApp from './start';
import { BaseUrl, DnsSentry } from './helpers/settings';
import { getToken } from './helpers/utility';
import GraphQLErrors from './helpers/graphQlErrors';
import * as Sentry from '@sentry/browser';
import { SentryLink } from 'apollo-link-sentry';

import 'antd/dist/antd.css';

Sentry.init({
  dsn: DnsSentry,
  defaultIntegrations: [new Sentry.Integrations.Breadcrumbs({ fetch: false })],
});

const AuthLink = (operation, next) => {
  const token = getToken();

  operation.setContext(context => ({
    ...context,
    headers: {
      ...context.headers,
      Authorization: `Bearer ${token}`,
    },
  }));

  return next(operation);
};

const linkError = onError(({ graphQLErrors, networkError }) => {
  GraphQLErrors(graphQLErrors);
  if (networkError) {
    console.log(`Network Error: ${networkError.message}`);
  }
});

const link = ApolloLink.from([
  AuthLink,
  linkError,
  new SentryLink(),
  createUploadLink({ uri: `${BaseUrl}/graphql` }),
]);

const client = new ApolloClient({
  link,
  cache: new Cache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <StartApp />
  </ApolloProvider>,
  document.getElementById('root')
);
