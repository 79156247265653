import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  getDashboardRequest: ['data'],
  getDashboardSuccess: null,
  getIndicatorsRequest: ['data'],
  getIndicatorsSuccess: null,
});

const INITIAL_STATE = Immutable({
  completedException: [],
  returnShipment: [],
  requests_initiated: [],
  requests_approved: [],
  requests_completed: [],
});

const getDashboard = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

const getIndicators = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

export default createReducer(INITIAL_STATE, {
  [Types.GET_DASHBOARD_SUCCESS]: getDashboard,
  [Types.GET_INDICATORS_SUCCESS]: getIndicators,
});
