import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { Links, SingleLinks } from '../SideBarAdmin/menuLinks';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from '../../../Pages/StoreLoginPage/redux';
import {
  MenuLabel,
  SideBarWrapper,
  SingleMenuLinks,
  SingleMenuLabel,
  SubMenuLabel,
  WorkCompany,
  ImgWrapper,
  InfoUser,
} from './style';
import logout_icon from '../SideBarAdmin/icons/logout.svg';
import user from '../SideBarAdmin/icons/user.png';
import logo from '../SideBarAdmin/icons/logo.png';

const { SubMenu } = Menu;

const SideBarMobileAdmin = () => {
  const getUrl = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const userName = useSelector(state => state.StoreLoginPage);

  const stripTrailingSlash = str => {
    if (str.substr(-1) === '/') {
      return str.substr(0, str.length - 1);
    }
    return str;
  };

  const url = stripTrailingSlash(getUrl.url);
  const getMenuItem = ({ innerItems }) => {
    const { key, label, leftIcon, children } = innerItems;
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <Link to={`${url}/${key}`}>
              <span>
                <span className="anticon">
                  <SVG src={leftIcon} alt={key} />
                </span>
                <SubMenuLabel>{label}</SubMenuLabel>
              </span>
            </Link>
          }
        >
          {children.map(child => {
            return (
              <Menu.Item key={child.key}>
                <Link to={`${url}/${child.key}`}>
                  {<MenuLabel>{child.label}</MenuLabel>}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span>
            <span className="anticon">
              <SVG src={leftIcon} alt={key} />
            </span>
            <MenuLabel>{label}</MenuLabel>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const singleMenu = ({ innerItems }) => {
    const { key, label, leftIcon } = innerItems;
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span>
            <span className="anticon">
              <SVG src={leftIcon} alt={key} />
            </span>
            <SingleMenuLabel>{label}</SingleMenuLabel>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  return (
    <SideBarWrapper
      onCollapse={f => setCollapsed(f)}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width="250px"
    >
      <WorkCompany>
        <ImgWrapper collapsed={collapsed}>
          <img
            src={logo}
            alt="logo"
            onClick={() => history.push('/administration')}
          />
        </ImgWrapper>
        {!collapsed ? (
          <div className="company">
            <span>Admin Dashboard</span>
          </div>
        ) : (
          ''
        )}
      </WorkCompany>
      <Menu mode="inline">
        <InfoUser collapsed={collapsed}>
          <img src={user} alt="avatar" />
          {!collapsed ? <p>{`Hello, ${userName?.infoUser.first_name}`}</p> : ''}
        </InfoUser>
        {Links.map(innerItems => getMenuItem({ innerItems }))}
        <SingleMenuLinks>
          <Menu>
            {SingleLinks.map(innerItems => singleMenu({ innerItems }))}
            <Menu.Item>
              <span onClick={() => dispatch(Creators.logout())}>
                <span className="anticon">
                  <SVG src={logout_icon} alt="logout icon" />
                </span>
                <SingleMenuLabel>Logout</SingleMenuLabel>
              </span>
            </Menu.Item>
          </Menu>
        </SingleMenuLinks>
      </Menu>
    </SideBarWrapper>
  );
};

export default SideBarMobileAdmin;
