import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  loginRequest: ['token'],
  loginSuccess: null,
  logout: null,
  checkAuthorization: null,
  checking: ['isBlock', 'message'],
  checkingSuccess: null,
});

const INITIAL_STATE = Immutable({
  token: null,
  profile: '',
  infoUser: '',
  isLogin: false,
  isBlock: false,
  message: '',
});

const login = (state = INITIAL_STATE, action) => state.merge({ ...action });
const block = (state = INITIAL_STATE, action) => state.merge({ ...action });

const logout = (state = INITIAL_STATE) => state.merge(INITIAL_STATE);

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_SUCCESS]: login,
  [Types.LOGOUT]: logout,
  [Types.CHECKING_SUCCESS]: block,
});
