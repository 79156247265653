import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { Types } from './index';

export function* getStatuses() {
  yield takeEvery(Types.GET_STATUSES_REQUEST, function*({
    statuses,
    order_by,
    order_direction,
    page,
  }) {
    yield put({
      type: Types.GET_STATUSES_SUCCESS,
      statuses: statuses?.data,
      total: statuses?.total,
      current_page: statuses?.current_page,
      order_by,
      order_direction,
      page,
    });
  });
}

export function* getStatus() {
  yield takeEvery(Types.GET_STATUS_REQUEST, function*({ status }) {
    yield put({
      type: Types.GET_STATUS_SUCCESS,
      status,
    });
  });
}

export function* getMethod() {
  yield takeEvery(Types.GET_METHOD_REQUEST, function*({ method }) {
    yield put({
      type: Types.GET_METHOD_SUCCESS,
      method,
    });
  });
}
export function* getCancelStatuses() {
  yield takeEvery(Types.GET_CANCEL_STATUSES_REQUEST, function*({
    cancelStatuses,
  }) {
    yield put({
      type: Types.GET_CANCEL_STATUSES_SUCCESS,
      cancelStatuses,
    });
  });
}

export function* getCancelMethod() {
  yield takeEvery(Types.GET_CANCEL_METHOD_REQUEST, function*({
    cancelMethods,
  }) {
    yield put({
      type: Types.GET_CANCEL_METHOD_SUCCESS,
      cancelMethods,
    });
  });
}

export function* getCourier() {
  yield takeEvery(Types.GET_COURIER_REQUEST, function*({ courier }) {
    yield put({
      type: Types.GET_COURIER_SUCCESS,
      courier,
    });
  });
}

export function* getRefund() {
  yield takeEvery(Types.GET_REFUND_REQUEST, function*({ refund }) {
    yield put({
      type: Types.GET_REFUND_SUCCESS,
      refund,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getStatuses),
    fork(getStatus),
    fork(getMethod),
    fork(getCancelStatuses),
    fork(getCancelMethod),
    fork(getCourier),
    fork(getRefund),
  ]);
}
