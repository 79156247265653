import React, { lazy, Suspense } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import Loading from '../helpers/Loading';

const routes = [
  //---Administration---
  {
    path: '',
    component: lazy(() => import('../Pages/AdministrationPages/PartnersPage')),
  },
  {
    path: 'shipments-management',
    component: lazy(() => import('../Pages/AdministrationPages/ReimportPage')),
  },
  {
    path: 'integration-access-tokens',
    component: lazy(() => import('../Pages/AdministrationPages/Integrator')),
  },
  {
    path: 'system-settings',
    component: lazy(() =>
      import('../Pages/AdministrationPages/SystemSettings')
    ),
  },

  //---Return---
  {
    path: 'return',
    component: lazy(() => import('../Pages/ReturnModule/HomePage')),
  },
  {
    path: 'return/return-statuses',
    component: lazy(() => import('../Pages/ReturnModule/ReturnStatusesPage')),
  },
  {
    path: 'return/return-statuses/:id',
    component: lazy(() =>
      import(
        '../Pages/ReturnModule/ReturnStatusesPage/StatusesTable/RefundPage'
      )
    ),
  },
  {
    path: 'return/settings/portal-customization',
    component: lazy(() => import('../Pages/ReturnModule/ReturnPortalPage')),
  },
  {
    path: 'return/settings/shipping-carriers',
    component: lazy(() => import('../Pages/ReturnModule/ShippingCarriersPage')),
  },
  {
    path: 'return/settings/email-notifications',
    component: lazy(() =>
      import('../Pages/ReturnModule/EmailNotificationsPage')
    ),
  },
  {
    path: 'return/settings/email-notifications/edit',
    component: lazy(() =>
      import('../Pages/ReturnModule/EmailNotificationsPage/EmailSinglePage')
    ),
  },
  {
    path: 'return/settings/return-rules',
    component: lazy(() => import('../Pages/ReturnModule/ReturnRulesPage')),
  },
  {
    path: 'return/settings/return-reasons',
    component: lazy(() => import('../Pages/ReturnModule/ReturnReasonsPage')),
  },

  // ----Route-(Shipments)----
  {
    path: 'route',
    component: lazy(() => import('../Pages/RouteModule/HomePage')),
  },
  {
    path: 'route/settings/courier-company',
    component: lazy(() => import('../Pages/RouteModule/ShippingCarriersPage')),
  },
  {
    path: 'route/settings/shipping-portal',
    component: lazy(() => import('../Pages/RouteModule/ShippingPortalPage')),
  },
  {
    path: 'route/settings/email-notifications',
    component: lazy(() => import('../Pages/RouteModule/EmailNotificationPage')),
  },
  {
    path: 'route/settings/email-notifications/edit',
    component: lazy(() =>
      import('../Pages/RouteModule/EmailNotificationPage/EmailSinglePage')
    ),
  },
  {
    path: 'route/overview',
    component: lazy(() => import('../Pages/RouteModule/ShipmentsOverviewPage')),
  },

  // ----Retrieve----
  {
    path: 'retrieve',
    component: lazy(() => import('../Pages/RetrieveModule/HomePage')),
  },
  {
    path: 'retrieve/settings/courier-company',
    component: lazy(() =>
      import('../Pages/RetrieveModule/ShippingCarriersPage')
    ),
  },
  {
    path: 'retrieve/settings/connectors',
    component: lazy(() => import('../Pages/RetrieveModule/ConnectorPage')),
  },

  // ----Company Settings----
  {
    path: 'company-settings',
    component: lazy(() => import('../Pages/CompanySettingsPage')),
  },

  // ----General Settings----
  {
    path: 'general-store-settings',
    component: lazy(() => import('../Pages/GeneralSettingsPage')),
  },
];

const AppRouter = () => {
  const { url } = useRouteMatch();
  return (
    <Suspense fallback={<Loading loading={true} />}>
      {routes.map(singleRoute => {
        const { path, ...otherProps } = singleRoute;
        return (
          <Route
            exact
            key={singleRoute.path}
            path={`${url}/${singleRoute.path}`}
            {...otherProps}
          />
        );
      })}
    </Suspense>
  );
};

export default AppRouter;
