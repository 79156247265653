import styled from 'styled-components';

export const WrapperH1 = styled.h1`
  font-weight: bold;
  font-size: 60px;
  line-height: 66px;
  color: ${props => props.color || props.theme.white};
  span {
    color: ${props => props.theme.green};
  }
`;

export const WrapperH2 = styled.h2`
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  color: ${props => props.color || props.theme.white};
  @media (min-width: ${props => props.theme.sm}) {
    font-size: 46px;
    line-height: 48px;
  }
  span {
    color: ${props => props.theme.green};
  }
`;

export const WrapperH3 = styled.h3`
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${props => props.color || props.theme.white};
`;

export const WrapperH4 = styled.h4`
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  color: ${props => props.color || props.theme.white};
  @media (min-width: ${props => props.theme.sm}) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const WrapperSubtitle1 = styled.h5`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: ${props => props.color || props.theme.black};
`;

export const WrapperSubtitle2 = styled.h6`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: ${props => props.color || props.theme.black};
`;

export const WrapperText1 = styled.p`
  font-family: 'Lato', serif;
  font-size: 16px;
  line-height: 23px;
  color: ${props => props.color || props.theme.white};
`;

export const WrapperText2 = styled.p`
  font-family: 'Lato', serif;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.color || props.theme.white};
`;

export const WrapperText3 = styled.p`
  font-family: 'Lato', serif;
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.color || props.theme.white};
`;
