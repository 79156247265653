import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { Types } from './index';

export function* getEmailRequest() {
  yield takeEvery(Types.GET_EMAIL_REQUEST, function*({ email }) {
    const { store_settings } = email;
    const joinEmail = { ...email, ...store_settings };
    try {
      yield put({
        type: Types.GET_EMAIL_SUCCESS,
        email: joinEmail,
        loading: false,
      });
    } catch (e) {}
  });
}

export default function* rootSaga() {
  yield all([fork(getEmailRequest)]);
}
