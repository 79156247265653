import dayjs from 'dayjs';

export function getDashboard(data) {
  const dateFormat = date => dayjs(new Date(date)).format('YYYY-MM-DD');
  const returns = data?.get_company_return_daily_indicators.reduce(
    (acc, { date, total_returns }) => {
      acc[dateFormat(date)] = total_returns;
      return acc;
    },
    []
  );

  const shipments = data?.get_company_return_daily_indicators.reduce(
    (acc, { date, total_shipments }) => {
      acc[dateFormat(date)] = total_shipments;
      return acc;
    },
    []
  );

  const returnShipment = [
    {
      name: 'returns',
      data: {
        ...returns,
      },
    },
    {
      name: 'shipments',
      data: {
        ...shipments,
      },
    },
  ];

  const completed = data?.get_company_return_daily_indicators.reduce(
    (acc, { date, requests_completed }) => {
      acc[dateFormat(date)] = requests_completed;
      return acc;
    },
    []
  );

  const exceptions = data?.get_company_return_daily_indicators.reduce(
    (acc, { date, requests_exceptions }) => {
      acc[dateFormat(date)] = requests_exceptions;
      return acc;
    },
    []
  );

  const completedException = [
    {
      name: 'completed',
      data: {
        ...completed,
      },
    },
    {
      name: 'exceptions',
      data: {
        ...exceptions,
      },
    },
  ];
  return { completedException, returnShipment };
}
