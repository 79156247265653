import dayjs from 'dayjs';

const start_date = (value, unit) =>
  dayjs()
    .subtract(value, unit)
    .format('YYYY-MM-DD');

const final_date = (value, unit) =>
  dayjs()
    .add(value, unit)
    .format('YYYY-MM-DD');

const format = value => dayjs(value).format('YYYY-MM-DD');

export { start_date, final_date, format };
