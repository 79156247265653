import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './reduxConfig/store';
import { Switch, Route, Redirect } from 'react-router-dom';

import App from './App/App';
import AppAdmin from './App/AppAdmin';
import Loading from './helpers/Loading';
const UserLoginPage = lazy(() => import('./Pages/StoreLoginPage'));
const CreateStorePage = lazy(() => import('./Pages/CreateStorePage'));
const PasswordRecovery = lazy(() => import('./Pages/PasswordRecoveryPage'));
const CreateNewPassword = lazy(() => import('./Pages/CreateNewPasswordPage'));
const AdminLoginPage = lazy(() => import('./Pages/AdminLoginPage'));
const CreatePassword = lazy(() =>
  import('./Pages/ConfirmationPage/CreatePassword')
);
const ConfirmationStorePage = lazy(() =>
  import('./Pages/ConfirmationPage/ConfirmationStorePage')
);
const ConfirmationUserStorePage = lazy(() =>
  import('./Pages/ConfirmationPage/ConfirmationUserStorePage')
);

function PrivateRoute({ children, ...rest }) {
  const logged = useSelector(state => state.StoreLoginPage.token);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        logged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PublicRouter() {
  return (
    <>
      <Suspense fallback={<Loading loading={true} />}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/">
              <UserLoginPage />
            </Route>
            <Route path="/signin">
              <UserLoginPage />
            </Route>
            <Route path="/create-account">
              <CreateStorePage />
            </Route>
            <Route path="/password-reset">
              <PasswordRecovery />
            </Route>
            <Route path="/reset-user-password">
              <CreateNewPassword />
            </Route>
            <Route path="/confirm-store-registration">
              <ConfirmationStorePage />
            </Route>
            <Route path="/confirm-user-registration">
              <ConfirmationUserStorePage />
            </Route>
            <Route path="/create-password">
              <CreatePassword />
            </Route>
            <Route path="/admin">
              <AdminLoginPage />
            </Route>
          </Switch>
        </ConnectedRouter>
      </Suspense>

      <ConnectedRouter history={history}>
        <Switch>
          <PrivateRoute path="/dashboard">
            <App />
          </PrivateRoute>
          <PrivateRoute path="/administration">
            <AppAdmin />
          </PrivateRoute>
        </Switch>
      </ConnectedRouter>
    </>
  );
}

export default PublicRouter;
