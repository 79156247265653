import route_icon from './icons/route.svg';
import return_icon from './icons/return.svg';
import retrieve_icon from './icons/retrieve.svg';
import settings_icon from './icons/settings.svg';
import notification_icon from './icons/notification.svg';

export const Links = [
  {
    key: 'route',
    label: 'Route',
    leftIcon: route_icon,
    role: 'user',
    children: [
      {
        key: 'route/settings/shipping-portal',
        label: 'Shipping Portal',
      },
      {
        key: 'route/settings/email-notifications',
        label: 'Email Notifications',
      },
      // {
      //   key: 'route/settings/connectors',
      //   label: 'Connectors',
      // },
      {
        key: 'route/settings/courier-company',
        label: 'Shipping Carriers',
      },
    ],
  },
  {
    key: 'return',
    label: 'Returns',
    leftIcon: return_icon,
    role: 'user',
    children: [
      {
        key: 'return/settings/portal-customization',
        label: 'Return Portal',
      },
      {
        key: 'return/settings/shipping-carriers',
        label: 'Shipping Carriers',
      },
      {
        key: 'return/settings/email-notifications',
        label: 'Email Notifications',
      },
      // {
      //   key: 'return/settings/catalog',
      //   label: 'Connectors',
      // },
      {
        key: 'return/settings/return-rules',
        label: 'Return Rules',
      },
      {
        key: 'return/settings/return-reasons',
        label: 'Return Reasons',
      },
    ],
  },
  {
    key: 'retrieve',
    label: 'Retrieve',
    leftIcon: retrieve_icon,
    role: 'user',
    children: [
      {
        key: 'retrieve/settings/courier-company',
        label: 'Shipping Carriers',
      },
      {
        key: 'retrieve/settings/connectors',
        label: 'Connectors',
      },
    ],
  },
];

export const SingleLinks = [
  {
    key: 'general-store-settings',
    label: 'General Settings',
    leftIcon: settings_icon,
    role: 'user',
  },
  // {
  //   key: '#',
  //   label: 'Notifications',
  //   leftIcon: notification_icon,
  //   role: 'user',
  // },
];
