import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { Types } from './index';
import { format } from '../helpers/date';

export function* getDataRange() {
  yield takeEvery(Types.GET_DATA_RANGE_REQUEST, function*({
    start_date,
    final_date,
  }) {
    yield put({
      type: Types.GET_DATA_RANGE_SUCCESS,
      start_date: format(start_date),
      final_date: format(final_date),
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getDataRange)]);
}
