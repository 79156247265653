import React, { useState, useEffect, useMemo } from 'react';
import { Menu } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/react-hooks';
import { GET_STORE_APPLICATION } from './query';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { Links, SingleLinks } from './menuLinks';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from '../../../Pages/StoreLoginPage/redux';
import {
  InfoUser,
  MenuLabel,
  SideBarWrapper,
  SingleMenuLinks,
  SingleMenuLabel,
  SubMenuLabel,
  WorkCompany,
  ImgWrapper,
} from './style';
import user from './icons/user.png';
import logout_icon from './icons/logout.svg';
import left from './icons/arrowLeft.svg';
import right from './icons/arrowRight.svg';
import { Text3 } from '../../Typography';

const { SubMenu } = Menu;

const SideBar = () => {
  const isDesktop = useMediaQuery({ query: '(max-width: 1200px)' });
  const getUrl = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const userName = useSelector(state => state.StoreLoginPage);
  const [collapsed, setCollapsed] = useState(false);

  const { data } = useQuery(GET_STORE_APPLICATION);

  useEffect(() => {
    localStorage.setItem('storeId', data?.get_my_stores[0].id);
  }, [data]);

  const stripTrailingSlash = str => {
    if (str.substr(-1) === '/') {
      return str.substr(0, str.length - 1);
    }
    return str;
  };

  useMemo(() => {
    setCollapsed(isDesktop);
  }, [isDesktop]);

  const url = stripTrailingSlash(getUrl.url);
  const getMenuItem = ({ innerItems }) => {
    const { key, label, leftIcon, children } = innerItems;
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <Link to={`${url}/${key}`}>
              <span>
                <span className="anticon">
                  <SVG src={leftIcon} alt={key} />
                </span>
                <SubMenuLabel>{label}</SubMenuLabel>
              </span>
            </Link>
          }
        >
          {children.map(child => {
            return (
              <Menu.Item key={child.key}>
                <Link to={`${url}/${child.key}`}>
                  {<MenuLabel>{child.label}</MenuLabel>}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span>
            <span className="anticon">
              <SVG src={leftIcon} alt={key} />
            </span>
            <MenuLabel>{label}</MenuLabel>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const singleMenu = ({ innerItems }) => {
    const { key, label, leftIcon } = innerItems;
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span>
            <span className="anticon">
              <SVG src={leftIcon} alt={key} />
            </span>
            <SingleMenuLabel>{label}</SingleMenuLabel>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  return (
    <SideBarWrapper
      onClick={() => {
        dispatch(Creators.checking(false));
      }}
      onCollapse={f => setCollapsed(f)}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width="250px"
    >
      <div className="trigger" onClick={() => setCollapsed(!collapsed)}>
        {!collapsed ? <SVG src={left} /> : <SVG src={right} />}
      </div>
      <WorkCompany>
        <ImgWrapper collapsed={collapsed}>
          <img
            src={data?.get_my_stores[0].logo_src}
            alt="logo"
            onClick={() => history.push('/dashboard/company-settings')}
          />
        </ImgWrapper>
        {!collapsed ? (
          <div className="company">
            <Text3 color="#495057">Your Company Is:</Text3>
            <span onClick={() => history.push('/dashboard/company-settings')}>
              {data?.get_my_stores[0].name}
            </span>
          </div>
        ) : (
          ''
        )}
      </WorkCompany>
      <Menu mode="inline">
        {Links.map(innerItems => getMenuItem({ innerItems }))}
        <InfoUser collapsed={collapsed}>
          <img src={user} alt="avatar" />
          {!collapsed ? <p>{`Hello, ${userName?.infoUser.first_name}`}</p> : ''}
        </InfoUser>
        <Menu>
          {SingleLinks.map(innerItems => singleMenu({ innerItems }))}
          <Menu.Item>
            <span
              onClick={() => {
                dispatch(Creators.logout());
                setTimeout(() => {
                  window.location.reload(false);
                }, 100);
              }}
            >
              <span className="anticon">
                <SVG src={logout_icon} alt="logout icon" />
              </span>
              <SingleMenuLabel>Logout</SingleMenuLabel>
            </span>
          </Menu.Item>
        </Menu>
      </Menu>
    </SideBarWrapper>
  );
};

export default SideBar;
