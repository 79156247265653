import { store } from '../reduxConfig/store';
import { Creators } from '../Pages/StoreLoginPage/redux';
import Notification from './notifications';
import * as Sentry from '@sentry/browser';

export default function graphQLErrors(graphQLErrors) {
  if (graphQLErrors) {
    // console.log(JSON.stringify(graphQLErrors, null, 2))
    graphQLErrors.map(({ extensions, validation, message }) => {
      Sentry.captureException(message);
      if (extensions.code === 401) store.dispatch(Creators.logout());
      if (extensions.code === 403) {
        store.dispatch(Creators.checking(true, message));
      }
      if (extensions.code === 422) {
        Object.entries(validation).map(v => Notification('error', v[1]));
      }
      if (extensions.code === 400) {
        Notification('error', message);
      }
      if (extensions.code === 500) {
        Notification('error', message);
      }
      return null;
    });
  }
}
