export function getKPIsRouter(data) {
  const total_visits = data?.get_company_aggregate_indicators.total_visits;
  const total_clicks = data?.get_company_aggregate_indicators.total_clicks;
  const engagement_rate =
    data?.get_company_aggregate_indicators.engagement_rate;
  const total_feedback = data?.get_company_aggregate_indicators.total_feedback;
  const average_stars = data?.get_company_aggregate_indicators.average_stars;

  return {
    total_visits,
    total_clicks,
    engagement_rate,
    total_feedback,
    average_stars,
  };
}
