import React, { useState } from 'react';
import { Layout, Drawer } from 'antd';
import Svg from 'react-inlinesvg';
import logo from './images/logo.svg';
import menu from './images/burg-menu.svg';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { AppWrapper, InnerContentLayout } from './style';
import AppRouter from './Routes';
import SideBarMobileAdmin from '../components/Administration/SideBarMobileAdmin';
import SideBarAdmin from '../components/Administration/SideBarAdmin';

const { Content } = Layout;

const AppAdmin = () => {
  const [visible, setVisible] = useState(false);

  const onclose = () => setVisible(false);

  return (
    <AppWrapper>
      <Drawer placement="left" visible={visible} onClose={onclose} width={272}>
        <SideBarMobileAdmin />
      </Drawer>
      <Layout>
        <SideBarAdmin />
        <InnerContentLayout>
          <Header>
            <Svg src={logo} />
            <Svg onClick={() => setVisible(true)} className="menu" src={menu} />
          </Header>
          <Content>
            <AppRouter />
          </Content>
          <Footer />
        </InnerContentLayout>
      </Layout>
    </AppWrapper>
  );
};

export default AppAdmin;
