import React from 'react';
import {
  WrapperH1,
  WrapperH2,
  WrapperH3,
  WrapperH4,
  WrapperSubtitle1,
  WrapperSubtitle2,
  WrapperText1,
  WrapperText2,
  WrapperText3,
} from './style';

export const H1 = ({ children, ...props }) => {
  return <WrapperH1 {...props}>{children}</WrapperH1>;
};

export const H2 = ({ children, ...props }) => {
  return <WrapperH2 {...props}>{children}</WrapperH2>;
};

export const H3 = ({ children, ...props }) => {
  return <WrapperH3 {...props}>{children}</WrapperH3>;
};

export const H4 = ({ children, ...props }) => {
  return <WrapperH4 {...props}>{children}</WrapperH4>;
};

export const SubTitle1 = ({ children, ...props }) => {
  return <WrapperSubtitle1 {...props}>{children}</WrapperSubtitle1>;
};

export const SubTitle2 = ({ children, ...props }) => {
  return <WrapperSubtitle2 {...props}>{children}</WrapperSubtitle2>;
};

export const Text1 = ({ children, ...props }) => {
  return <WrapperText1 {...props}>{children}</WrapperText1>;
};

export const Text2 = ({ children, ...props }) => {
  return <WrapperText2 {...props}>{children}</WrapperText2>;
};

export const Text3 = ({ children, ...props }) => {
  return <WrapperText3 {...props}>{children}</WrapperText3>;
};
