import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const GraphqlErrorBoundary = props => {
  const { isBlock, message } = useSelector(state => state.StoreLoginPage);

  if (isBlock) {
    return (
      <ContentWrapper>
        <InnerContent>
          <div className="number">
            <p>
              403<span>.</span>
            </p>
          </div>

          <div className="message">
            <p>Forbidden</p>
            <span>{message}</span>
          </div>
        </InnerContent>
      </ContentWrapper>
    );
  }
  return props.children;
};

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
  font-size: 18px;
  letter-spacing: 1px;
  font-family: Poppins, serif;
  color: ${props => props.theme.white};
`;

export const InnerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 660px) {
    flex-wrap: wrap;

    .number {
      margin-bottom: 40px;
    }
  }
  .number {
    font-weight: bold;
    font-size: 120px;
    line-height: 120px;
    span {
      color: ${props => props.theme.green};
    }
  }

  .message {
    margin-left: 48px;
    font-weight: bold;
    font-size: 46px;
    line-height: 48px;
    span {
      margin-top: 16px;
      width: 310px;
      display: block;
      font-family: Lato, serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
    }
  }
`;

export default GraphqlErrorBoundary;
