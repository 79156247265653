import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { Types } from './index';

export function* getRulesRequest() {
  yield takeEvery(Types.GET_RULES_REQUEST, function*({ rules }) {
    const typeRules = JSON.parse(rules?.get_return_rule_types || '[]');
    const actions = JSON.parse(rules?.get_return_rule_actions || '[]');
    const conditions = JSON.parse(rules?.get_return_rule_conditions || '[]');
    const reasons = rules?.get_company_return_reasons;
    const category = rules?.get_company_item_categories;
    try {
      yield put({
        type: Types.GET_RULES_SUCCESS,
        actions,
        conditions,
        typeRules,
        reasons,
        category,
      });
    } catch (e) {}
  });
}

export function* getEditRule() {
  yield takeEvery(Types.GET_EDIT_REQUEST, function*({ rule }) {
    try {
      yield put({
        type: Types.GET_EDIT_SUCCESS,
        rule,
      });
    } catch (e) {}
  });
}

export default function* rootSaga() {
  yield all([fork(getRulesRequest), fork(getEditRule)]);
}
