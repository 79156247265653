import partner from './icons/partners.svg';
import reImport from './icons/reimport.svg';
import integration from './icons/integration.svg';
import settings from './icons/settings.svg';

export const Links = [
  {
    key: '',
    label: 'Partners',
    leftIcon: partner,
  },
  {
    key: 'shipments-management',
    label: 'Shipments Manag.',
    leftIcon: reImport,
  },
  {
    key: 'integration-access-tokens',
    label: 'Integrations',
    leftIcon: integration,
  },
  {
    key: 'system-settings',
    label: 'System Settings',
    leftIcon: settings,
  },
];

export const SingleLinks = [];
