import dayjs from 'dayjs';

export function getRouteDashboard(data) {
  const dateFormat = date => dayjs(new Date(date)).format('YYYY-MM-DD');
  const visits = data?.get_company_total_visits.reduce(
    (acc, { date, total_visits }) => {
      acc[dateFormat(date)] = total_visits;
      return acc;
    },
    []
  );

  const clicks = data?.get_company_total_visits.reduce(
    (acc, { date, total_clicks }) => {
      acc[dateFormat(date)] = total_clicks;
      return acc;
    },
    []
  );

  const visitsClicks = [
    {
      name: 'visits',
      data: {
        ...visits,
      },
    },
    {
      name: 'clicks',
      data: {
        ...clicks,
      },
    },
  ];

  return { visitsClicks };
}
