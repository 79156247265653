import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { Types } from './index';
import { getKPIs } from '../helpers/getKPIs';
import { getDashboard } from '../helpers/getDashboard';

export function* getDashboardHome() {
  yield takeEvery(Types.GET_DASHBOARD_REQUEST, function*({ data }) {
    const { completedException, returnShipment } = getDashboard(data);

    yield put({
      type: Types.GET_DASHBOARD_SUCCESS,
      completedException,
      returnShipment,
    });
  });
}

export function* getIndicators() {
  yield takeEvery(Types.GET_INDICATORS_REQUEST, function*({ data }) {
    const {
      requests_approved,
      requests_completed,
      requests_initiated,
    } = getKPIs(data);
    yield put({
      type: Types.GET_INDICATORS_SUCCESS,
      requests_approved,
      requests_completed,
      requests_initiated,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getDashboardHome), fork(getIndicators)]);
}
