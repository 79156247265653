import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Provider } from 'react-redux';
import { store } from './reduxConfig/store';
import theme from './theme';
import PublicRouter from './router';
import reset from 'styled-reset/lib';
import Boot from './reduxConfig/boot';

const GlobalStyle = createGlobalStyle`
  ${reset}
  body {
     font-family: 'Poppins', serif;
     background: #212429;
  }
`;

const StartApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Provider store={store}>
        <PublicRouter />
      </Provider>
    </ThemeProvider>
  );
};

Boot()
  .then(() => StartApp())
  .catch(error => console.error(error));

export default StartApp;
