import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  getEmailRequest: ['email'],
  getEmailSuccess: null,
});

const INITIAL_STATE = Immutable({
  email: {},
  loading: false,
});

const getEmail = (state = INITIAL_STATE, action) => state.merge({ ...action });
const getLoading = (state = INITIAL_STATE, action) =>
  state.merge({ ...action, loading: true });

export default createReducer(INITIAL_STATE, {
  [Types.GET_EMAIL_REQUEST]: getLoading,
  [Types.GET_EMAIL_SUCCESS]: getEmail,
});
