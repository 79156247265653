import { all } from 'redux-saga/effects';
import LoginSaga from '../Pages/StoreLoginPage/redux/saga';
import EmailReturnSaga from '../Pages/ReturnModule/EmailNotificationsPage/redux/saga';
import RulesReturnSaga from '../Pages/ReturnModule/ReturnRulesPage/redux/saga';
import StatusReturnSaga from '../Pages/ReturnModule/ReturnStatusesPage/redux/saga';
import PeriodFilterSaga from '../components/PeriodFilterComponent/redux/saga';
import DashboardHome from '../Pages/ReturnModule/HomePage/redux/saga';
import DashRouteHome from '../Pages/RouteModule/HomePage/redux/saga';
import OrdersRouteSaga from '../Pages/RouteModule/ShipmentsOverviewPage/redux/saga';
import IntegrationsSaga from '../components/Integrations/redux/saga';

export default function* rooSaga() {
  yield all([
    LoginSaga(),
    EmailReturnSaga(),
    RulesReturnSaga(),
    StatusReturnSaga(),
    PeriodFilterSaga(),
    DashboardHome(),
    DashRouteHome(),
    OrdersRouteSaga(),
    IntegrationsSaga(),
  ]);
}
