import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  getStatusesRequest: ['statuses'],
  getStatusesSuccess: null,
  getStatusRequest: ['status'],
  getStatusSuccess: null,
  getMethodRequest: ['method'],
  getMethodSuccess: null,
  getCancelMethodRequest: ['cancelMethod'],
  getCancelMethodSuccess: null,
  getCancelStatusesRequest: ['cancelStatuses'],
  getCancelStatusesSuccess: null,
  getCourierRequest: ['courier'],
  getCourierSuccess: null,
  getRefundRequest: ['courier'],
  getRefundSuccess: null,
});

const INITIAL_STATE = Immutable({
  statuses: [],
  total: 0,
  order_by: '',
  order_direction: '',
  page: 1,
  current_page: 1,
  status: undefined,
  courier: undefined,
  method: undefined,
  cancelMethods: false,
  cancelStatuses: false,
  refund: {},
});

const getStatuses = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

const getStatus = (state = INITIAL_STATE, action) => state.merge({ ...action });

const getMethod = (state = INITIAL_STATE, action) => state.merge({ ...action });

const getCancelMethod = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });
const getCancelStatuses = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

const getCourier = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

const getRefund = (state = INITIAL_STATE, action) => state.merge({ ...action });

export default createReducer(INITIAL_STATE, {
  [Types.GET_STATUSES_SUCCESS]: getStatuses,
  [Types.GET_STATUS_SUCCESS]: getStatus,
  [Types.GET_CANCEL_STATUSES_SUCCESS]: getCancelStatuses,
  [Types.GET_METHOD_SUCCESS]: getMethod,
  [Types.GET_CANCEL_METHOD_SUCCESS]: getCancelMethod,
  [Types.GET_COURIER_SUCCESS]: getCourier,
  [Types.GET_REFUND_SUCCESS]: getRefund,
});
