const theme = {
  green: '#17D892',
  gradient: 'linear-gradient(349.6deg, #0B5C44 5.3%, #17D892 93.57%)',
  black: '#212429',
  white: '#FFFFFF',
  grey1: '#F8F9FA',
  grey2: '#DDE2E5',
  grey3: '#ACB5BD',
  grey4: '#495057',
  blue: '#007EE5',
  red: '#E5006E',
  orange: '#E5A500',

  sm: '480px',
  cmq7: '767px',
  lg: '1023px',
  xl: '1200px',
  cmqxl: '1441px',
  custom: '1850px',
};

export default theme;
