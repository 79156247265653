import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  getDashRouteRequest: ['data'],
  getDashRouteSuccess: null,
  getIndicatorsRouteRequest: ['data'],
  getIndicatorsRouteSuccess: null,
});

const INITIAL_STATE = Immutable({
  visitsClicks: [],
  total_visits: [],
  total_clicks: [],
  engagement_rate: [],
  total_feedback: [],
  average_stars: [],
});

const getDashRoute = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

const getIndicatorsRoute = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

export default createReducer(INITIAL_STATE, {
  [Types.GET_DASH_ROUTE_SUCCESS]: getDashRoute,
  [Types.GET_INDICATORS_ROUTE_SUCCESS]: getIndicatorsRoute,
});
