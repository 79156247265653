import styled from 'styled-components';
import { Layout } from 'antd';

export const FooterWrapper = styled(Layout.Footer)`
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: ${props => props.padding || '40px 35px'};
  background: ${props => props.theme.black};
  color: ${props => props.theme.grey2};
  @media (min-width: ${props => props.theme.lg}) {
    max-width: ${props => props.maxwidth || '100%'};
  }
  p {
    margin: 0;
  }
  @media (min-width: ${props => props.theme.sm}) {
    justify-content: space-between;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  p {
    margin-left: 20px;
  }
  @media (min-width: ${props => props.theme.sm}) {
    margin-bottom: initial;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.grey2};
  p {
    padding: 10px;
  }
`;
