import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

export const SideBarWrapper = styled(Sider)`
  background: ${props => props.theme.white};
  padding-top: 25px;
  overflow: auto;
  height: 100vh;
  @media (min-width: ${props => props.theme.lg}) {
    display: none;
  }
  .trigger {
    cursor: pointer;
    padding: 8px;
    background: ${props => props.theme.green};
    margin-top: 24px;
    position: absolute;
    right: 0;
    top: 0;
    svg {
      path {
        fill: #212429;
      }
    }
  }
`;

export const SubMenuLabel = styled.span`
  font-family: Lato, serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.black};
  text-transform: uppercase;
`;
export const MenuLabel = styled(SubMenuLabel)`
  font-weight: normal;
`;

export const SingleMenuLabel = styled(SubMenuLabel)`
  font-weight: bold;
  font-size: 12px;
`;

export const WorkCompany = styled.div`
  margin-bottom: 24px;
  padding: 0 16px 16px;
  border-bottom: 1px solid ${props => props.theme.grey2};
  img {
    cursor: pointer;
  }
  span {
    cursor: pointer;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    color: ${props => props.theme.black};
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.collapsed ? '45px' : '85px')};
  height: ${props => (props.collapsed ? '45px' : '85px')};
  border: ${props => (props.collapsed ? 'unset' : '1px solid #DDE2E5')};
  margin-bottom: 20px;
  margin-top: ${props => (props.collapsed ? '40px' : 'unset')};
`;

export const SingleMenuLinks = styled.div`
  margin-top: 16px;
  padding: 24px 0;
  border-top: 1px solid ${props => props.theme.grey2};
`;

export const InfoUser = styled.div`
  padding: 0 0 24px 16px;
  margin-bottom: ${props => (props.collapsed ? '50px' : '20px')};
  img {
    width: 60px;
    height: 60px;
    margin-bottom: 14px;
  }
  p {
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    color: ${props => props.theme.black};
  }
`;
