import styled from 'styled-components';
import { Layout } from 'antd';

export const AppWrapper = styled.div`
  .ant-layout {
    background: none;
    min-height: 100vh;
  }
`;

export const InnerContentLayout = styled(Layout)`
  width: 100vh;
  padding: 0 16px;
  @media (min-width: ${props => props.theme.cmq7}) {
    padding: 0 48px;
  }
  .menu {
    cursor: pointer;
    @media (min-width: ${props => props.theme.lg}) {
      display: none;
    }
  }

  .ant-layout-header {
    margin: 24px 0;
    padding: 16px 0;
    @media (min-width: ${props => props.theme.cmq7}) {
      //padding: 24px 0;
    }
  }
  .ant-layout-content {
    color: #fff;
  }
  .ant-layout-footer {
    padding: 24px 0;
    //margin-top: 80px;
  }
`;
