import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { start_date, final_date } from '../helpers/date';

export const { Types, Creators } = createActions({
  getDataRangeRequest: ['start_date', 'final_date'],
  getDataRangeSuccess: null,
});

const INITIAL_STATE = Immutable({
  start_date: start_date(1, 'month'),
  final_date: final_date(1, 'day'),
});

const getDataRange = (state = INITIAL_STATE, action) =>
  state.merge({ ...action });

export default createReducer(INITIAL_STATE, {
  [Types.GET_DATA_RANGE_SUCCESS]: getDataRange,
});
