import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { Types } from './index';
import { getKPIsRouter } from '../helpers/getKPIs';
import { getRouteDashboard } from '../helpers/getDashboard';

export function* getDashboardHome() {
  yield takeEvery(Types.GET_DASH_ROUTE_REQUEST, function*({ data }) {
    const { visitsClicks } = getRouteDashboard(data);

    yield put({
      type: Types.GET_DASH_ROUTE_SUCCESS,
      visitsClicks,
    });
  });
}

export function* getIndicators() {
  yield takeEvery(Types.GET_INDICATORS_ROUTE_REQUEST, function*({ data }) {
    const {
      total_visits,
      total_clicks,
      engagement_rate,
      total_feedback,
      average_stars,
    } = getKPIsRouter(data);
    yield put({
      type: Types.GET_INDICATORS_ROUTE_SUCCESS,
      total_visits,
      total_clicks,
      engagement_rate,
      total_feedback,
      average_stars,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getDashboardHome), fork(getIndicators)]);
}
