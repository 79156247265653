import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { Types } from './index';

export function* formIntegration() {
  yield takeEvery(Types.FORM_REQUEST, function*({ integration }) {
    yield put({
      type: Types.FORM_SUCCESS,
      integration,
    });
  });
}

export function* reset() {
  yield takeEvery(Types.FORM_RESET, function*() {
    yield put({
      type: Types.FORM_RESET_SUCCESS,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(formIntegration), fork(reset)]);
}
