import React from 'react';
import SVG from 'react-inlinesvg';
import { FooterWrapper, LinksWrapper, LogoWrapper } from './style';
import logo_R from './img/logo_R.svg';
import { Text3 } from '../Typography';

const Footer = ({ maxwidth }) => {
  return (
    <FooterWrapper maxwidth={maxwidth}>
      <LogoWrapper>
        <SVG src={logo_R} />
        <Text3>{`Relay Cloud ©${new Date().getFullYear()}`}</Text3>
      </LogoWrapper>
      <LinksWrapper>
        <p>Terms & Conditions</p>
        <p>Privacy Policy</p>
        <p>Help</p>
      </LinksWrapper>
    </FooterWrapper>
  );
};

export default Footer;
